import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function CameraHardware() {

  return (

    <div className="max-w-6xl px-0 py-4 sm:py-4 mt-0 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">
      
      {/* SECTION */}
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white mb-4 sm:mb-8 w-full">
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative">
          
          <div className="w-full sm:w-8/12 order-last sm:order-1">

            <div className="w-full">  
              <div className="text-blueone p-6 sm:p-8 pb-0 sm:pb-0">
                <h2 className="text-xl mb-2 font-medium">
                  1080P / IP66 / IR
                  <br/>Base-mounted waterproof Rear View Camera
                </h2>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative pb-4 sm:pb-5">

              <div className="w-full sm:w-5/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/car.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="text-sm">
                        Suitable for any type of vehicles, popular for bus & heavy vehicles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-7/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/play.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <ul className="list-none text-sm">
                        <li><strong>Sensor:</strong> 1/2.8" CMOS Image Sensor</li>
                        <li><strong>Lens:</strong> 3.6mm Lens</li>
                        <li><strong>IR:</strong> 18 Infrared LEDs, 5M range</li>
                        <li><strong>Resolution:</strong> 1920px (H) × 1080px (V)</li>
                        <li><strong>Recording:</strong> 1080p @ 30fps, H:85°</li>
                        <li><strong>Operating temperature:</strong> -20° to +50° Celcius</li>
                        <li><strong>Dimensions:</strong> 77mm x 54mm x 94mm</li>
                        <li><strong>Weight:</strong> 350g</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full sm:w-4/12 order-1 sm:order-last">  
            <div className="flex flex-col sm:flex-row items-center justify-center overflow-hidden relative h-auto sm:h-[280px] pt-8 sm:pt-0">
              <StaticImage
                alt="camera hardware"
                src="../../../../static/images/mdvr/hardware/ir-rear.png"
                className="w-[40%] sm:w-[153px] object-fit relative"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SECTION */}
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white mb-4 sm:mb-8 w-full">
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative">
          
          <div className="w-full sm:w-8/12 order-last sm:order-1">

            <div className="w-full">  
              <div className="text-blueone p-6 sm:p-8 pb-0 sm:pb-0">
                <h2 className="text-xl mb-2 font-medium">
                  1080P / IP66 / IR
                  <br/>Side-mounted waterproof Camera
                </h2>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative pb-4 sm:pb-5">

              <div className="w-full sm:w-5/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/car.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="text-sm">
                        Suitable for any type of vehicles, popular for bus & heavy vehicles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-7/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/play.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <ul className="list-none text-sm">
                        <li><strong>Sensor:</strong> 1/3" CMOS Image Sensor</li>
                        <li><strong>Lens:</strong> 4.0mm Lens</li>
                        <li><strong>IR:</strong> 10 Infrared LEDs, 3M range</li>
                        <li><strong>Resolution:</strong> 1920px (H) × 1088px (V)</li>
                        <li><strong>Recording:</strong> 1080p @ 30fps, H:85°</li>
                        <li><strong>Operating temperature:</strong> -10° to +60° Celcius</li>
                        <li><strong>Dimensions:</strong> 32mm x 52mm x 59mm</li>
                        <li><strong>Weight:</strong> 60g</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full sm:w-4/12 order-1 sm:order-last">  
            <div className="flex flex-col sm:flex-row items-center justify-center overflow-hidden relative h-auto sm:h-[280px] pt-8 sm:pt-0">
              <StaticImage
                alt="camera hardware"
                src="../../../../static/images/mdvr/hardware/ir-side.png"
                className="w-[40%] sm:w-[120px] object-fit relative"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SECTION */}
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white mb-4 sm:mb-8 w-full">
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative">
          
          <div className="w-full sm:w-8/12 order-last sm:order-1">

            <div className="w-full">  
              <div className="text-blueone p-6 sm:p-8 pb-0 sm:pb-0">
                <h2 className="text-xl mb-2 font-medium">
                  1080P
                  <br/>In-cabin Wide-lens Camera
                </h2>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative pb-4 sm:pb-5">

              <div className="w-full sm:w-5/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden flex items-center">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/car.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                      <p className="ml-2 font-medium">S-Type</p>
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="text-sm">
                        Suitable for slanting windshield vehicles (Sean, police cars, MPV, SUV, Van, RV).
                      </p>
                    </div>
                  </div>

                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden flex items-center">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/truck.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                      <p className="ml-2 font-medium">L-Type</p>
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="text-sm">
                        Suitable for upright windshield vehicles (Truck. Bus, Pickup, Trailer).
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-7/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/play.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <ul className="list-none text-sm">
                        <li><strong>Sensor:</strong> 1/2.8" SONY CMOS Sensor</li>
                        <li><strong>Lens:</strong> 60mm Lens</li>
                        <li><strong>Resolution:</strong> 1920px (H) × 1080px (V)</li>
                        <li><strong>Recording:</strong> 1080p @ 30fps, H:120°</li>
                        <li><strong>Operating temperature:</strong> -10° to +60° Celcius</li>
                        <li><strong>Dimensions:</strong> 32mm x 100mm x 55mm</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full sm:w-4/12 order-1 sm:order-last">  
            <div className="flex flex-col sm:flex-row items-center justify-center overflow-hidden relative h-auto sm:h-[280px] pt-8 sm:pt-0">
              <StaticImage
                alt="camera hardware"
                src="../../../../static/images/mdvr/hardware/wide-lens.png"
                className="w-[40%] sm:w-[90px] object-fit relative"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SECTION */}
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white mb-4 sm:mb-8 w-full">
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative">
          
          <div className="w-full sm:w-8/12 order-last sm:order-1">

            <div className="w-full">  
              <div className="text-blueone p-6 sm:p-8 pb-0 sm:pb-0">
                <h2 className="text-xl mb-2 font-medium">
                  1080P / IP66 / IR
                  <br/>External waterproof infrared Camera
                </h2>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative pb-4 sm:pb-5">

              <div className="w-full sm:w-5/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/car.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="text-sm">
                        Suitable for external recording purposes, in particular motorcycles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-7/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/play.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <ul className="list-none text-sm">
                        <li><strong>Sensor:</strong> 1/3" CMOS Sensor</li>
                        <li><strong>Lens:</strong> 2.8mm Lens</li>
                        <li><strong>Resolution:</strong> 1920px (H) × 1088px (V)</li>
                        <li><strong>Recording:</strong> 1080p @ 30fps, H:85°</li>
                        <li><strong>Operating temperature:</strong> -10° to +60° Celcius</li>
                        <li><strong>Dimensions:</strong> 45mm x 53mm x 30mm</li>
                        <li><strong>Auto gain control</strong></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full sm:w-4/12 order-1 sm:order-last">  
            <div className="flex flex-col sm:flex-row items-center justify-center overflow-hidden relative h-auto sm:h-[280px] pt-8 sm:pt-0">
              <StaticImage
                alt="camera hardware"
                src="../../../../static/images/mdvr/hardware/ext-camera.png"
                className="w-[40%] sm:w-[130px] object-fit relative"
              />
            </div>
          </div>
        </div>
      </div>

      {/* SECTION */}
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white w-full">
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative">
          
          <div className="w-full sm:w-8/12 order-last sm:order-1">

            <div className="w-full">  
              <div className="text-blueone p-6 sm:p-8 pb-0 sm:pb-0">
                <h2 className="text-xl mb-2 font-medium">
                  In-cabin LCD Monitor
                  <br/>5" / 7" / 10"
                </h2>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative pb-4 sm:pb-5">

              <div className="w-full sm:w-5/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/car.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <p className="text-sm">
                        Suitable for any type of vehicles, this monitor allows drivers to view real-time camera footage.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full sm:w-7/12">  
                <div className="overflow-hidden flex flex-col">  
                  <div className="p-6 py-1 sm:p-8 sm:py-3 text-blueone">
                    <div className="overflow-hidden">
                      <StaticImage
                        alt="Wifi"
                        src="../../../../static/images/mdvr/icons/blue/play.png"
                        className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                      />
                    </div>

                    <div className="pt-1 pb-2">
                      <ul className="list-none text-sm">
                        <li><strong>Screen size:</strong> 10.1" 16:9 LED HD</li>
                        <li><strong>Resolution:</strong> 1280 (H) × 800 (V)</li>
                        <li><strong>Color system:</strong> PAL/NTSC</li>
                        <li><strong>Speaker:</strong> Optional</li>
                        <li><strong>Signal Input:</strong> AV/BNC/VGA/HD/USB</li>
                        <li><strong>Operating temperature:</strong> -10° to +50° Celcius</li>
                        <li><strong>Dimensions:</strong> 245mm x 365mm x 12mm</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="w-full sm:w-4/12 order-1 sm:order-last">  
            <div className="flex flex-col sm:flex-row items-center justify-center overflow-hidden relative h-auto sm:h-[280px] pt-8 sm:pt-0">
              <StaticImage
                alt="camera hardware"
                src="../../../../static/images/mdvr/hardware/lcd.png"
                className="w-[40%] sm:w-[153px] object-fit relative"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
    
  );
};

export default CameraHardware;
