import * as React from "react";
import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";
import PackageOne from "./gettingstarted/packageone";
import PackageTwo from "./gettingstarted/packagetwo";
import PackageThree from "./gettingstarted/packagethree";
import PackageFive from "./gettingstarted/packagefive";
import CameraHardware from "./gettingstarted/camerahardware";
import UpgradeSlider from "./upgradeslider";

function GettingStarted() {

  return (

    <div className="pt-12 pb-8 md:pt-8 md:pb-16 lg:pt-8 lg:pb-16">

      {/*first group*/}
      <div className="package-section px-8 md:px-8 lg:px-8">
        <h2 className="max-w-5xl mx-auto mt-0 md:mt-12 mb-2 sm:mb-8 flex flex-col sm:flex-row">
          <div className="w-full sm:w-4/12">
            <h2 className="text-xl sm:text-2xl font-bold text-bluetext md:text-3xl md:leading-[2.75rem] mb-4 sm:mb-0">
              Getting Started
            </h2>
          </div>
          <div className="w-full sm:w-8/12">
            <p className="text-bluetext mb-4 sm:mb-8 mt-0 sm:mt-2">
              Choose between the highly advanced 8- or 4-Channel MDVR with or without live video streaming capability, 
              or the smaller 2-Channel fleet dashcam with Advanced Driver Assistance System (ADAS) feature.
            </p>

            <div className="flex flex-col sm:flex-row items-start sm:items-center sm:justify-start mt-8 sm:mt-8 md:mt-0">
              <a
                href="https://apps.katsana.com/get-quotation"
                className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center px-6 pt-2 pb-3 md:text-base leading-0 bg-primary"
              >
                Need help? Contact us
              </a>
              <a
                href="tel:+60377334474"
                className="ml-0 sm:ml-4 mt-4 sm:mt-0 text-sm text-primary md:flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold">or call us +603 7733 4474 (Kuala Lumpur, GMT +8)</span>
              </a>
            </div>
          
          </div>
        </h2>

        <div className="max-w-6xl px-0 py-8 mt-0 md:mt-3 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-4 lg:gap-6 w-full z-10">

            <div className="bg-white rounded md:ml-0 overflow-hidden flex flex-col justify-self-auto">
              <div className="flex items-center min-h-[110px] relative w-full flex-grow">
                <div className="relative overflow-hidden h-[120px] w-full">
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/mdvr/getting-started/dsm.png"
                    className="absolute object-none object-left-top w-[250px] md:-top-[65px] md:-left-[20px] -top-[52%] -left-[5%]"
                  />
                </div>
              </div>
              <div className="px-4 py-6 md:px-6 md:pt-2 md:pb-6">
                <h5 className="text-base font-semibold leading-5 mb-2 sm:mb-1">
                  Advanced AI MDVR with Live Stream, ADAS, DSM & BSD
                </h5>
                <p className="text-sm">8 Channels / SSD & Cloud Storage</p>
              </div>
            </div>

            <div className="bg-white rounded md:ml-0 overflow-hidden flex flex-col justify-self-auto">
              <div className="flex items-center min-h-[110px] relative w-full flex-grow">
                <div className="relative overflow-hidden h-[140px] w-full md:-top-0">
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/mdvr/getting-started/mdvr.png"
                    className="absolute object-none object-left-top w-[300px] md:-top-[50px] md:-left-[45px] -top-[35%] -left-[15%]"
                  />
                </div>
              </div>
              <div className="px-4 py-6 md:px-6 md:pt-2 md:pb-6">
                <h5 className="text-base font-semibold leading-5 mb-2 sm:mb-1">
                  Standard MDVR with Local Storage & Live Stream
                </h5>
                <p className="text-sm">4 Channels / SSD & Cloud Storage</p>
              </div>
            </div>

            <div className="bg-white rounded md:ml-0 overflow-hidden flex flex-col justify-self-auto">
              <div className="flex items-center min-h-[110px] relative w-full flex-grow">
                <div className="relative overflow-hidden h-[120px] w-full">
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/mdvr/getting-started/lite-mdvr-bike.png"
                    className="absolute object-none object-right-top w-[263px] sm:w-[319.4px] md:top-[10px] md:-right-[10px] top-[20%] -right-[3%]"
                  />
                </div>
              </div>
              <div className="px-4 py-6 md:px-6 md:pt-2 md:pb-6">
                <h5 className="text-base font-semibold leading-5 mb-2 sm:mb-1">
                  Lite MDVR for Motorcycles with Live Stream
                </h5>
                <p className="text-sm">2 Channels / SD Card & Cloud Storage</p>
              </div>
            </div>

            <div className="bg-white rounded md:ml-0 overflow-hidden flex flex-col justify-self-auto">
              <div className="flex items-center min-h-[110px] relative w-full flex-grow">
                <div className="relative overflow-hidden h-[140px] w-full md:-top-0">
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/mdvr/getting-started/ai-fleet.png"
                    className="absolute object-none object-left-top w-[120px] sm:w-[155px] md:top-[25px] md:left-[25px] top-[35%] left-[12%]"
                  />
                </div>
              </div>
              <div className="px-4 py-6 md:px-6 md:pt-2 md:pb-6">
                <h5 className="text-base font-semibold leading-5 mb-2 sm:mb-1">
                  AI Fleet Dashcam with Live Stream, ADAS, DSM, BSD
                </h5>
                <p className="text-sm">4 Channels / SD-Card & Cloud Storage</p>
              </div>
            </div>

            <div className="bg-white rounded md:ml-0 overflow-hidden flex flex-col justify-self-auto">
              <div className="flex items-center min-h-[110px] relative w-full flex-grow">
                <div className="relative overflow-hidden h-[120px] sm:h-[140px] w-full">
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/mdvr/getting-started/driver-id.png"
                    className="absolute object-none object-right-top w-[180px] sm:w-[200px] md:-top-[10px] md:right-[10px] -top-[8%] right-[2%]"
                  />
                </div>
              </div>
              <div className="px-4 py-6 md:px-6 md:pt-2 md:pb-6">
                <h5 className="text-base font-semibold leading-5 mb-2 sm:mb-1">
                  Essential Upgrades
                </h5>
                <p className="text-sm">Popular hardware and software upgrades for added monitoring & management capabilities</p>
              </div>
            </div>

            <div className="bg-white rounded md:ml-0 overflow-hidden flex flex-col justify-self-auto">
              <div className="flex items-center relative w-full flex-grow">
                <div className="relative overflow-hidden h-[120px] sm:h-[140px] w-full">
                  <StaticImage
                    alt="katsana"
                    src="../../../static/images/mdvr/getting-started/fully-managed.png"
                    className="absolute object-cover"
                  />
                </div>
              </div>
              <div className="px-4 py-6 md:px-6 md:pt-2 md:pb-6 bg-[#254091] text-white">
                <h5 className="text-center text-base font-semibold leading-5 mb-2 sm:mb-1">
                  Fully Managed, End-to-End
                </h5>
                <p className="text-sm text-center">We manage everything from SIM & Data packages to installations, support & warranty services.</p>
              </div>
            </div>

          </div>

        </div>

        <PackageOne/>

        <PackageTwo/>

        <PackageThree/>

        <PackageFive/>

      </div>
      {/*end first group*/}

      {/*second group*/}
      <div id="camera-hardware-options" className="package-section mt-6 sm:mt-0 px-8 md:px-8 lg:px-8">
        <h2 className="max-w-5xl mx-auto pt-0 md:pt-12 mb-2 sm:mb-8 flex flex-col sm:flex-row">
          <div className="w-full sm:w-4/12">
            <h2 className="text-xl sm:text-2xl font-bold text-bluetext md:text-3xl md:leading-[2.75rem] mb-4 sm:mb-0">
              Camera & Hardware options
            </h2>
          </div>
          <div className="w-full sm:w-8/12">
            <p className="text-bluetext mb-4 sm:mb-8 mt-0 sm:mt-2">
              KATSANA MDVR solutions comes with numerous camera options to suit different needs & environmental exposures.
            </p>

            <div className="flex flex-col sm:flex-row items-start sm:items-center sm:justify-start mt-8 sm:mt-8 md:mt-0">
              <a
                href="https://apps.katsana.com/get-quotation"
                className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center px-6 pt-2 pb-3 md:text-base leading-0 bg-primary"
              >
                Need help? Contact us
              </a>
              <a
                href="tel:+60377334474"
                className="ml-0 sm:ml-4 mt-4 sm:mt-0 text-sm text-primary md:flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold">or call us +603 7733 4474 (Kuala Lumpur, GMT +8)</span>
              </a>
            </div>
          
          </div>
        </h2>

        <CameraHardware/>
      </div>

      {/*third group*/}
      <div className="package-section mt-6 sm:mt-0 px-8 md:px-8 lg:px-8">
        <h2 className="max-w-5xl mx-auto mt-0 md:mt-12 mb-2 sm:mb-8 flex flex-col sm:flex-row">
          <div className="w-full sm:w-4/12 pr-8 md:pr-8 lg:pr-8">
            <h2 className="text-xl sm:text-2xl font-bold text-bluetext md:text-3xl md:leading-[2.75rem] mb-4 sm:mb-0">
              Popular solution upgrades
            </h2>
          </div>
          <div className="w-full sm:w-8/12 pr-8 md:pr-8 lg:pr-8">
            <p className="text-bluetext mb-4 sm:mb-8 mt-0 sm:mt-2">
              Upgrade your MDVR solution with new features and capabilities
              listed below. Pick the hardware that best suit your fleet needs.
            </p>

            <div className="flex flex-col sm:flex-row items-start sm:items-center sm:justify-start mt-8 sm:mt-8 md:mt-0">
              <a
                href="https://apps.katsana.com/get-quotation"
                className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center px-6 pt-2 pb-3 md:text-base leading-0 bg-primary"
              >
                Need help? Contact us
              </a>
              <a
                href="tel:+60377334474"
                className="ml-0 sm:ml-4 mt-4 sm:mt-0 text-sm text-primary md:flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold">or call us +603 7733 4474 (Kuala Lumpur, GMT +8)</span>
              </a>
            </div>
          
          </div>
        </h2>

        <div className="max-w-6xl mx-auto">
          <UpgradeSlider/>
        </div>

      </div>

    </div>
    
  );
};

export default GettingStarted;
