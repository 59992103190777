import * as React from "react";
import LazyLoad from "react-lazyload";
import {
  MobileView,
  // MobileOnlyView,
  BrowserView,
  // TabletView,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Cta from "../components/cta";
import Companies from "../components/companies";
import CompaniesMobile from "../components/companiesmobile";
import { StaticImage } from "gatsby-plugin-image";
import MdvrAi from "../components/mdvr/mdvrai";
import AdvancedManagement from "../components/mdvr/advancedmanagement";
import GettingStarted from "../components/mdvr/gettingstarted";

import * as Styles from "../styles/index.module.css";
import "../styles/herofeatures.css";

const FuelPage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <ParallaxProvider>
      <Layout pageTitle="Home Page">
        {/* MetaTag */}
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <title>
            AI MDVR for Fleet Operators
          </title>
          <meta
            name="description"
            content="Capture indisputable video evidence using KATSANA MDVR solutions, powered by AI."
          />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href="https://www.katsana.com/mdvr/" />

          {/* Open Graph */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.katsana.com/mdvr/" />
          <meta
            property="og:title"
            content="AI MDVR for Fleet Operators"
          />
          <meta
            property="og:description"
            content="Capture indisputable video evidence using KATSANA MDVR solutions, powered by AI."
          />
          <meta
            property="og:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
          <meta
            property="og:image:alt"
            content="Capture Indisputable Video Evidence, with AI."
          />
          <meta property="og:image:width" content="1280" />
          <meta property="og:image:height" content="640" />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@katsanagps" />
          <meta name="twitter:creator" content="@katsanagps" />

          <meta
            name="twitter:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
        </Helmet>
        {/* Hero Area */}
        <div
          className={
            Styles.tilt +
            " bg-gradient-to-br from-[hsla(211,55%,22%,1)] via-[hsla(211,45%,16%,1)] to-[hsla(211,45%,16%,1)] pt-20 md:pt-24 xl:pt-28 md:pb-16 "
          }
        >
          {/* Set Max Width */}
          <div className="relative mx-auto max-w-primary">
            <div className="flex justify-center">
              
              {/* Hero Text & CTA */}
              <div className="flex flex-col px-8 pt-4 pb-0 md:pb-8 lg:w-full xl:max-w-[820px] z-10 items-center justify-center">
                <p className="mb-0 leading-6 md:mb-2 md:leading-8 sm:text-md text-bluegray md:max-w-lg text-center">
                  Solutions for MDVR - Mobile Digital Video Recorder / Fleet Dashcam
                </p>
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white text-center leading-10 md:leading-10 lg:leading-none">
                  Capture indisputable video evidence, with AI.
                </h1>
              </div>

            </div>

            <div className="max-w-5xl p-8 pb-4 mx-auto -mt-3 sm:-mt-8 flex justify-center">
              <div className="h-auto w-full text-center relative">
                <div 
                  className={`${Styles.bubble1} absolute right-[-6%] md:right-[6.5%] bottom-[-15%] md:-bottom-3 z-10`}
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >

                  <div className="w-auto h-auto scale-75 md:scale-100 overflow-hidden">
                     <StaticImage
                        alt="katsana"
                        src="../../static/images/mdvr/hero/mobile-mdvr.png"
                        className="h-auto rounded-md w-[105px] md:w-[165px] object-cover"
                      />
                  </div>
                </div>

                <div 
                  className={`${Styles.bubble2} absolute left-[-10%] md:left-0 top-[25%] md:top-[38%] z-10`}
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <div className="w-auto h-auto scale-75 md:scale-100">
                    <StaticImage
                        alt="katsana"
                        src="../../static/images/mdvr/hero/balloon-1.png"
                        className="h-auto md:h-auto rounded-md w-[170px] md:w-[290px] object-cover"
                      />
                  </div>
                </div>

                <div 
                  className={`${Styles.bubble3} absolute left-[-12%] md:left-[-2.5%] -bottom-[15%] md:-bottom-12 z-10`}
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <div className="w-auto h-auto scale-75 md:scale-100">
                    <StaticImage
                        alt="katsana"
                        src="../../static/images/mdvr/hero//alerts.png"
                        className="h-auto md:h-auto rounded-md w-[185px] md:w-[335px] object-cover"
                      />
                  </div>
                </div>

                <StaticImage
                  alt="katsana"
                  src="../../static/images/mdvr/hero/hero-mdvr.png"
                  className="h-auto rounded-md w-full md:w-[800px]"
                />
              </div>
            </div>

            {/* Companies / Client */}
            <LazyLoad>
              <div className="pt-0 pb-5 md:pt-0 md:pb-0">
                <BrowserView>
                  <Companies />
                </BrowserView>

                <MobileView>
                  <CompaniesMobile />
                </MobileView>
              </div>
            </LazyLoad>

          </div>
        </div>

        {/* MDVR AI */}
        <div className="bg-bluedark relative">
          <div className="bg-bluedark absolute -top-[24px] sm:-top-[39.5px] [clip-path:polygon(100%_100%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px]"></div>
          
          <div className="max-w-6xl mx-auto">
            <div>
              <div className="max-w-5xl py-1 md:py-0 lg:pt-5 lg:pb-8 mx-auto">
                <MdvrAi />
              </div>
            </div>
          </div>

          <div className="bg-bluedark absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* Advanced Management */}
        <div
          className={
            " bg-[hsla(202,28%,77%,1)] mt-[-1px] pb-0 relative"
          }
        > 
          <div className="sm:max-w-6xl md:max-w-7xl lg:max-w-8xl mx-auto">
            <div className="w-full py-1 md:py-0 lg:pt-10 lg:pb-10 mx-auto">
              <AdvancedManagement/>
            </div>
          </div>
        
        </div>

        {/* Getting Started */}
        <div
          className={
            "bg-offwhite mt-[-1px] pb-0 relative"
          }
        >
          <div className="rotate-[-180deg] md:rotate-[-180deg] backdrop-opacity-5 backdrop-invert bg-[hsla(202,28%,77%,1)] absolute top-[0px] md:top-[0px] sm:top-[0px] [clip-path:polygon(100%_100%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px]"></div>
          
          <div className="max-w-6xl mx-auto">
            <div className="max-w-6xl py-1 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
              <GettingStarted />
            </div>
          </div>

          <div className="skew-bottom bg-[hsla(198,26%,93%,1)] absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>


        <Cta />

      </Layout>
    </ParallaxProvider>
  );
};

export default FuelPage;
