import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";
import VidSlider from "./vidslider";

function MdvrAi() {

  return (
    <div className="pl-8 pr-0 pt-6 pb-10 md:p-8 lg:p-8">

      <h2 className="max-w-3xl pr-8 sm:pr-0 mx-auto mt-0 md:mt-2 text-2xl font-bold text-white  md:text-center md:text-4xl md:leading-[2.75rem]">
        Mobile Digital Video Recorder (MDVR) with Live Streaming & AI to protect <span className="text-primary">your drivers, vehicle & cargo safety</span>
      </h2>

      <p className="max-w-2xl pr-8 sm:pr-0 mx-auto mt-4 mb-4 md:mb-8 lg:mb-8 text-xs leading-5 md:text-center md:text-lg text-bluegray md:leading-8">
        KATSANA robust MDVR, Fleet Dashcam & DSM (Driver Status Monitor) solutions protect your
        business reputation by providing indisputable video evidence in event of accidents and insurance disputes.
      </p>

      <div className="max-w-6xl pt-4 pb-3 md:pt-6 md:pb-12 mx-auto">
        <LazyLoad>
          <VidSlider/>
        </LazyLoad>
      </div>

      <div className="flex flex-col pr-8 sm:pr-0 items-center justify-center w-full z-10 mt-4 md:mt-6 lg:mt-6">        
        
        <div className="flex flex-col sm:flex-row items-stretch justify-center">  
          <div className="mt-2 sm:mt-0 w-full sm:w-1/3 max-h-120 mx-0 sm:mx-6 items-stretch flex flex-col items-center rounded-xl overflow-hidden relative">
            <div className="overflow-hidden">
              <StaticImage
                alt="Wifi"
                src="../../../static/images/mdvr/icons/wifi.png"
                className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px]"
              />
            </div>

            <div className="flex-grow pt-2 pb-4 md:pt-4 md:pb-2 lg:pt-2 lg:pb-6">
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-white leading-4 md:leading-6 lg:leading-6 mb-4">
                Stream live driving video
              </h3>
              <p className="text-sm sm:text-base text-white opacity-70 mb-4">
                Monitor drivers and the road around them with live streaming views from up to 8 cameras at once.
              </p>
              <p className="text-sm sm:text-base text-white opacity-70">
                Viewable on desktop browsers, IOS and Android apps.
              </p>
            </div>
          </div>

          <div className="mt-2 sm:mt-0 w-full sm:w-1/3 max-h-120 mx-0 sm:mx-6 items-stretch flex flex-col items-center rounded-xl overflow-hidden relative">
            <div className="overflow-hidden">
              <StaticImage
                alt="User"
                src="../../../static/images/mdvr/icons/user.png"
                className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px]"
              />
            </div>

            <div className="flex-grow pt-2 pb-4 md:pt-4 md:pb-2 lg:pt-2 lg:pb-6">
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-white leading-4 md:leading-6 lg:leading-6 mb-4">
                Driver behavior videos
              </h3>
              <p className="text-sm sm:text-base text-white opacity-70 mb-4">
                AI-powered video telematics detect unsafe driver behaviors such as use of mobile phone, smoking and fatigue driving.
              </p>
              <p className="text-sm sm:text-base text-white opacity-70">
                Short footages are automatically uploaded to the cloud for review.
              </p>
            </div>
          </div>

          <div className="mt-2 sm:mt-0 w-full sm:w-1/3 max-h-120 mx-0 sm:mx-6 items-stretch flex flex-col items-center rounded-xl overflow-hidden relative">
            <div className="overflow-hidden">
              <StaticImage
                alt="TV"
                src="../../../static/images/mdvr/icons/tv.png"
                className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px]"
              />
            </div>

            <div className="flex-grow pt-2 pb-4 md:pt-4 md:pb-2 lg:pt-2 lg:pb-6">
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-white leading-4 md:leading-6 lg:leading-6 mb-4">
                View video history remotely
              </h3>
              <p className="text-sm sm:text-base text-white opacity-70 mb-4">
                With up to 300 hours of driving video stored in the MDVR, you can stream and download historical videos without having to physically access the vehicle.
              </p>
              <p className="text-sm sm:text-base text-white opacity-70">
                Super convenient for large fleet operators.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-stretch justify-center mt-0 md:mt-4">  
          <div className="mt-2 sm:mt-0 w-full sm:w-1/3 max-h-120 mx-0 sm:mx-6 items-stretch flex flex-col items-center rounded-xl overflow-hidden relative">
            <div className="overflow-hidden">
              <StaticImage
                alt="Direction"
                src="../../../static/images/mdvr/icons/direction.png"
                className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px]"
              />
            </div>

            <div className="flex-grow pt-2 pb-4 md:pt-4 md:pb-2 lg:pt-2 lg:pb-6">
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-white leading-4 md:leading-6 lg:leading-6 mb-4">
                GPS tracking integration
              </h3>
              <p className="text-sm sm:text-base text-white opacity-70 mb-4">
                View live vehicle location on the same platform, together with its video stream feed.
              </p>
              <p className="text-sm sm:text-base text-white opacity-70">
                As always, viewable on desktop browsers, IOS and Android apps.
              </p>
            </div>
          </div>

          <div className="mt-2 sm:mt-0 w-full sm:w-1/3 max-h-120 mx-0 sm:mx-6 items-stretch flex flex-col items-center rounded-xl overflow-hidden relative">
            <div className="overflow-hidden">
              <StaticImage
                alt="Shield"
                src="../../../static/images/mdvr/icons/shield.png"
                className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px]"
              />
            </div>

            <div className="flex-grow pt-2 pb-4 md:pt-4 md:pb-2 lg:pt-2 lg:pb-6">
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-white leading-4 md:leading-6 lg:leading-6 mb-4">
                Driver behavior score
              </h3>
              <p className="text-sm sm:text-base text-white opacity-70">
                Powered by DriveMark to provide deep insight into driver behavior patterns. 
                Drivers are ranked based on risk profile, enabling fleet managers to quickly identify at risk drivers early before its too late.
              </p>
            </div>
          </div>

          <div className="mt-2 sm:mt-0 w-full sm:w-1/3 max-h-120 mx-0 sm:mx-6 items-stretch flex flex-col items-center rounded-xl overflow-hidden relative">
            <div className="overflow-hidden">
              <StaticImage
                alt="Briefcase"
                src="../../../static/images/mdvr/icons/briefcase.png"
                className="w-[50px] h-[50px] sm:w-[65px] sm:h-[65px]"
              />
            </div>

            <div className="flex-grow pt-2 pb-4 md:pt-4 md:pb-2 lg:pt-2 lg:pb-6">
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-white leading-4 md:leading-6 lg:leading-6 mb-4">
                Local on-site support
              </h3>
              <p className="text-sm sm:text-base text-white opacity-70 mb-4">
                We take pride in providing best in class support and customer service for fleet operators.
              </p>
              <p className="text-sm sm:text-base text-white opacity-70">
                From customer success onboarding, to after-sales inspection, we have you covered for a piece of mind.
              </p>
            </div>
          </div>
        </div>

      </div>

      <div className="max-w-6xl pt-4 pb-3 md:pt-8 md:pb-4 mx-auto">
        <div className="flex flex-col sm:flex-row mt-8 sm:mt-8 items-start text-left justify-start md:items-center md:mt-0 md:text-center justify-center">
          <a
            href="https://apps.katsana.com/get-quotation"
            className="self-start font-semibold text-white rounded-full md:self-center px-6 pt-1 pb-3 md:text-base leading-0 bg-primary"
          >
            Request Consultation
          </a>
          <a
            href="tel:+60377334474"
            className="ml-0 sm:ml-4 mt-4 sm:mt-0 text-sm text-primary md:flex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="font-bold">or call us +603 7733 4474 (Kuala Lumpur, GMT +8)</span>
          </a>
        </div>
      </div>

    </div>
    
  );
};

export default MdvrAi;
