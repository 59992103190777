import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function PackageFive() {

  return (

    <div className="max-w-6xl px-0 py-4 sm:py-4 mt-0 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">
      
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white">
        
        {/* SECTION HEADING */}
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative border-b border-gray-100 package-five">
          <div className="w-full sm:w-6/12 p-6 sm:p-8 pb-[220px] sm:pb-8 h-auto sm:h-[320px]">  
            <div className="mb-4 sm:mb-6">
              <p className="text-sm sm:text-md text-bluetext mb-1">
                DC04-A1 - AI Fleet Dashcam
              </p>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-blueone leading-5 md:leading-6 lg:leading-6 mr-0">
                <span className="text-primary">AI Fleet Dashcam for Fleets</span> with 4-Channels, ADAS, DSM and Blind Spot Monitor (BSD) 1080P, H.265
              </h3>
            </div>

            <div className="text-blueone text-sm">
              <p className="mb-4 mt-0 sm:mt-[40px] w-full sm:w-10/12">
                <strong>Advanced Plug-n-Play Fleet-focused Dashcam</strong> with a built-in AI processor to detect driving events like forward
                collision and improper driver behavior such a using mobile phone usage and distracted driving.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-6/12 pt-0 md:pt-4 px-4 md:pr-4 flex items-stretch justify-center relative grow">
            <p className="text-[10px] text-blueone absolute bottom-[12px] sm:bottom-[3%] right-auto sm:right-[5%]">Note: Device physical appearance may change over time.</p>
          </div>
        </div>

        {/* SECTION */}
        <div className="grid grid-cols-1 gap-0 md:grid-cols-3 md:gap-0 lg:gap-0 w-full z-10">
          
          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/car.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Advanced Driver Assistance System (ADAS) </strong> 
                  provides drivers with advanced warning
                  of dangerous road conditions in front 
                  of the vehicle.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex flex-col justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/hard-drive.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Driver Status Monitor (DSM)</strong> detects
                  driver attentiveness during driving
                  such as fatigue, use of phone and
                  smoking.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/direction.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Blind Spot Monitor (BSD)</strong> alerts the driver
                  when objects are detected within 
                  the blindspot areas of the vehicles.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/tv.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Built-in 1-channel 1080P camera and 
                  3 external HD channels</strong> to support 
                  rear/cabin/passenger cameras.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/chip.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Supports low-power sleep mode  </strong>
                  to reduce power consumption when
                  the vehicle is inactive.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/hard-drive.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Supports large Micro-SD storage</strong> of up to 256GB storage.
                </p>
              </div>
            </div>
          </div>

        </div>

        {/* SECTION */}
        
      </div>

    </div>
    
  );
};

export default PackageFive;
