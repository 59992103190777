import * as React from "react";
import { Fragment, useState, useEffect } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronUpIcon } from "@heroicons/react/solid";
import VideoTwo from "../../../../static/video/DMS_Phone_usage.mp4"
import { Dialog, Transition } from "@headlessui/react";

const VidPopupTwo = () => {
  let [isOpen, setIsOpen] = useState(false);

  function closeVidTwo() {
    setIsOpen(false);
  }

  function openVidTwo() {
    setIsOpen(true);
  }

  return (
    <div>
      <div class="group rounded-xl w-72 md:w-[360px] cursor-pointer relative shadow-2xl ring-4 ring-transparent hover:ring-primary hover:ring-4 border-solid overflow-hidden hover:transition-all hover:duration-1000">
            
        {/*Background image*/}
        <div className="transition-all ease-in-out duration-1000 group-hover:ease-in-out h-[162px] md:h-[202px] dsm-1"></div>
        
        <div onClick={openVidTwo} className="overflow-hidden rounded-xl vid flex flex-col text-white opacity-0 absolute bottom-0 z-10 transition-all ease-in-out duration-1000 group-hover:opacity-100 group-hover:ease-in-out">
          <video autoPlay muted loop width="360">
            <source src={VideoTwo} type="video/mp4" />
          </video>
        </div>

      </div>
      
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeVidTwo}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-80" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="drop-shadow-2xl absolute mx-auto my-auto sm:m-auto inset-x-0 inset-y-0 w-full sm:max-w-xl lg:max-w-2xl text-left align-middle transition-all transform min-h-min sm:min-h-min sm:h-[430px] px-4"
            >
              <div>
                <div className="bg-gray-800 rounded-xl overflow-hidden">
                  <div>
                    <div className="flex flex-row items-center justify-between w-full py-4 px-6">
                      <h4 className="text-white text-sm sm:text-base">
                        DMS: Phone Usage Detection
                      </h4>
                      <XIcon className="w-4 h-4 sm:w-6 sm:h-6 text-white cursor-pointer" onClick={closeVidTwo} />
                    </div>

                    <video autoPlay muted loop controls>
                      <source src={VideoTwo} type="video/mp4" />
                    </video>
                  </div>
                </div>
               
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default VidPopupTwo;
