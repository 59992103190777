import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function PackageOne() {

  return (

    <div className="max-w-6xl px-0 py-4 sm:py-4 mt-0 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">
      
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white">
        
        {/* SECTION HEADING */}
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative border-b border-gray-100 package-one">
          <div className="w-full sm:w-6/12 p-6 sm:p-8 pb-[250px] sm:pb-8">  
            <div className="mb-4 sm:mb-6">
              <p className="text-sm sm:text-md text-bluetext mb-1">
                MD08-X1 - The complete video monitoring package
              </p>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-blueone leading-5 md:leading-6 lg:leading-6">
                <span className="text-primary">Mobile Digital Video Record (MDVR)</span> with Live Streaming, Blindspot Detection (BSD), Driver Status Monitor (DSM) and
                Advanced Driver Assistance Systems (ADAS)
              </h3>
            </div>

            <div className="ml-0 sm:ml-6 text-blueone">
              <p className="text-sm mb-4">
                <strong>KATSANA Live MDVR</strong> provides unparalled video monitoring capabilities, allowing fleet operators to stream in-cabin and external cameras in real-time.
              </p>
              <p className="text-sm mb-4">
                Its <strong>AI-powered Driver Status Monitor (DSM)</strong> camera provides detection of risky driver behavior and alerts you for review.
              </p>
              <p className="text-sm">
                When paired with ADAS, this package <strong>alerts drivers of dangerous road conditions</strong> in front of the vehicles.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-6/12 pt-0 md:pt-4 px-4 md:pr-4 flex items-stretch justify-center relative grow">
            <p className="text-[10px] text-blueone absolute bottom-[12px] sm:bottom-[3%] right-auto sm:right-[5%]">Note: Device physical appearance may change over time.</p>
          </div>
        </div>

        {/* SECTION */}
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative border-b border-gray-100">
          
          <div className="w-full sm:w-6/12 border-b sm:border-b-0 sm:border-r border-gray-100">  
            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative h-[280px]">
              <div className="w-full sm:w-6/12">  
                <div className="text-blueone p-6 sm:p-8">
                  <p className="text-sm mb-4">
                    <strong>Live Video Streaming</strong> from up to 8 channels AHD, Digital & AI cameras, viewable on an integrated KATSANA Fleet Management platform & KATSANA mobile apps on iOS and Android smartphones.
                  </p>
                </div>
              </div>

              <div className="w-full sm:w-6/12">
                <StaticImage
                  alt="fleet management system"
                  src="../../../../static/images/mdvr/hero/mobile-mdvr.png"
                  className="w-[110px] sm:w-[150px] object-none relative top-[-25px] left-[50%] translate-x-[-50%] sm:translate-x-0 sm:top-8 sm:left-10"
                />
              </div>
            </div>
          </div>

          <div className="w-full sm:w-6/12 dsm-monitor">  
            <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative h-auto sm:h-[280px]">
              <div className="w-full sm:w-6/12">  
                <div className="text-blueone p-6 sm:p-8">
                  <p className="text-sm mb-4">
                    <strong>Driver Status Monitor (DSM)</strong> enables detection of driver facial features, reducing accidents from fatigue, distracted driving, phone use, drowsiness etc.
                  </p>
                </div>
              </div>

              <div className="w-full sm:w-6/12 h-[150px]"></div>
            </div>
          </div>
        </div>

        {/* SECTION */}
        <div className="grid grid-cols-1 gap-0 md:grid-cols-3 md:gap-0 lg:gap-0 w-full z-10">
          
          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/direction.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Live GPS tracking</strong> provides real-time location update every 5-20 seconds, with 12 months location history.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex flex-col justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/truck.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Fleet Management System</strong> with advanced monitoring capabilities & extensive operational reports for day-to-day fleet operations.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/hard-drive.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Large internal storage</strong> of up to 4TB on ruggedized Solid-State Drive (SSD) & SD card ensues long video recording and longevity in operational environment.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/upload.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Secure cloud upload & storage</strong> when risky driver behavior is detected, preventing potential data loss in event of accidents.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/download.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Remote video download</strong> allows fleet managers to review and download driving videos without having to physically access the vehicles.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/lock-open.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Secure physical file storage</strong> with key locks to prevent unauthorised access and tempering of video evidence.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/chat-mark.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Automatic video recording</strong> when vehicle is switched on. Includes detection of shocks which triggers mandatory recording so that important videos are not lost.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/puzzle.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Extensive upgrade options</strong> with numerous hardware and software features to suit your fleet's uniques requirement.
                  <br/><a href="#camera-hardware-options" className="text-primary">View upgrade list ></a>
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex border-b border-r-0">  
            <div className="w-full p-6 sm:p-8 text-blueone flex flex-col items-center justify-center">
              <a
                href="https://apps.katsana.com/get-quotation"
                className="inline-block px-5 py-2 font-semibold text-white rounded-full md:self-center px-6 pt-2 pb-3 md:text-base leading-0 bg-primary"
              >
                Need help? Contact us
              </a>
              <a
                href="mailto:sales@katsana.com"
                className="mt-3 sm:mt-3 text-sm text-primary md:flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold">or email sales@katsana.com</span>
              </a>
            </div>
          </div>

        </div>

        {/* SECTION */}
        
      </div>

    </div>
    
  );
};

export default PackageOne;
