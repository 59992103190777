import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function PackageThree() {

  return (

    <div className="max-w-6xl px-0 py-4 sm:py-4 mt-0 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">
      
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white">
        
        {/* SECTION HEADING */}
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative border-b border-gray-100 package-three">
          <div className="w-full sm:w-6/12 p-6 sm:p-8 pb-[220px] sm:pb-8 h-auto sm:h-[360px]">  
            <div className="mb-4 sm:mb-6">
              <p className="text-sm sm:text-md text-bluetext mb-1">
                MD04-A1 - Lite MDVR for Motorcycles
              </p>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-blueone leading-5 md:leading-6 lg:leading-6 mr-0 sm:mr-16">
                <span className="text-primary">Lite Mobile Digital Video Record (MDVR)</span> with 2-Channels, local SC-Card storage, optional live stream & cloud storage
              </h3>
            </div>

            <div className="text-blueone text-sm">
              <p className="mt-0 sm:mt-[60px] w-full sm:w-8/12">
                <strong>This Lite MDVR for motorcycles</strong> comes in small physical size fit for motorcycles. It has 2-Channels cameras (front and rear) with support of up to 512GB SD-Card storage. 
              </p>
            </div>
          </div>

          <div className="w-full sm:w-6/12 pt-0 md:pt-4 px-4 md:pr-4 flex items-stretch justify-center relative grow">
            <p className="text-[10px] text-blueone absolute bottom-[12px] sm:bottom-[3%] right-auto sm:right-[5%]">Note: Device physical appearance may change over time.</p>
          </div>
        </div>

        {/* SECTION */}
        <div className="grid grid-cols-1 gap-0 md:grid-cols-3 md:gap-0 lg:gap-0 w-full z-10">
          
          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/car.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Small physical size</strong> designed specifically for motorbikes and three-wheelers.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex flex-col justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/hard-drive.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Internal storage</strong> of up to 512GB on
                  industrial SD Card with one extra slot
                  for backup against corrupt files.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/lock-open.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Secure physical file storage</strong> with 
                  key locks to prevent unauthorised 
                  access and tempering of video evidence.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/tv.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>H.264 video compression</strong> that reduces
                  file size. Videos recorded in 4MP resolution.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/boxes.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Energy efficient</strong> with low power
                  consumption and optimized heat 
                  dissipation.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/chip.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Supercapacitors</strong> that protects
                  file recording and integrity in event 
                  of power interruption.
                </p>
              </div>
            </div>
          </div>          

        </div>

        {/* SECTION */}
        <div className="flex flex-row items-start sm:items-center overflow-hidden relative bg-blueone text-white px-4 sm:px-8 py-3">
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round" className="w-[36px] h-[36px] sm:w-[16px] sm:h-[16px] -mt-2 sm:mt-0"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
          <p className="text-[12px] ml-1">*Despite its small soze. MD-04-A1 MDVR can be upgraded with Live Streaming and Cloud storage features.</p>
        </div>
        
      </div>

    </div>
    
  );
};

export default PackageThree;
