import * as React from "react";
import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";
import {
  MobileView,
  // MobileOnlyView,
  BrowserView,
  // TabletView,
} from "react-device-detect";

function ReachUs() {

  return (
    <div>

      <div className="md:pt-10 pb-4 md:pb-2">
        <h2 className="px-8 max-w-3xl mx-auto mt-12 md:mt-0 mb-2 text-2xl font-bold text-blueone  md:text-center md:text-4xl md:leading-[2.75rem]">
          Advanced fleet dashcam, MDVR & video telematics solutions for modern fleet management
        </h2>
      </div>

      <div className="w-full px-0 py-4 md:py-8 mt-0 md:mt-6 pb-8 md:pb-4 mx-auto block sm:flex sm:items-center sm:justify-center">

        <div className="overflow-scroll sm:overscroll-none no-scrollbar px-8 sm:px-0">

          <BrowserView>
            <LazyLoad>
              <StaticImage
                alt="advanced management"
                src="../../../static/images/mdvr/vidcam/adv-image.png"
                className="w-[1000px] sm:w-full md:w[1557px] lg:w[1557px] object-none relative mr-8 sm:mr-0"
              />
            </LazyLoad>
          </BrowserView>

          <MobileView>
            <LazyLoad>
              <StaticImage
                alt="advanced management"
                src="../../../static/images/mdvr/vidcam/adv-image-mobile.svg"
                className="w-[1016px] object-none relative mr-8 sm:mr-0"
              />
            </LazyLoad>
          </MobileView>

        </div>

      </div>

    </div>
    
  );
};

export default ReachUs;
