import * as React from "react";

// Import Swiper React components
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";

import VidPopupOne from "./videopopup/vidpopupone";
import VidPopupTwo from "./videopopup/vidpopuptwo";
import VidPopupThree from "./videopopup/vidpopupthree";
import VidPopupFour from "./videopopup/vidpopupfour";

import { Fragment, useState, useEffect } from "react";

const VidSlider = () => {
  let [isOpen, setIsOpen] = useState(false);

  function closeVidOne() {
    setIsOpen(false);
  }

  function openVidOne() {
    setIsOpen(true);
  }

  function closeVidTwo() {
    setIsOpen(false);
  }

  function openVidTwo() {
    setIsOpen(true);
  }

  function closeVidThree() {
    setIsOpen(false);
  }

  function openVidThree() {
    setIsOpen(true);
  }

  function closeVidFour() {
    setIsOpen(false);
  }

  function openVidFour() {
    setIsOpen(true);
  }

  return (
    <div className="dashcam max-w-6xl mx-auto">

      <Swiper
        modules={[FreeMode]}
        freeMode={true}
        grabCursor={true}
        spaceBetween={25}
        centeredSlides={true}
        className={`relative ${
          isDesktop ? " !overflow-visible" : " overflow-hidden"
        }`}
      >
        <SwiperSlide>
          <VidPopupOne />
        </SwiperSlide>

        <SwiperSlide>
          <VidPopupTwo />
        </SwiperSlide>

        <SwiperSlide>
          <VidPopupThree />
        </SwiperSlide>

        <SwiperSlide>
          <VidPopupFour />
        </SwiperSlide>

      </Swiper>
    </div>
  );
};

export default VidSlider;
