import * as React from "react";
// import LazyLoad from "react-lazyload";
import { StaticImage } from "gatsby-plugin-image";

function PackageTwo() {

  return (

    <div className="max-w-6xl px-0 py-4 sm:py-4 mt-0 sm:p-4 pb-4 mx-auto flex flex-col items-center justify-center">
      
      <div className="rounded-lg shadow-sm overflow-hidden relative bg-white">
        
        {/* SECTION HEADING */}
        <div className="flex flex-col sm:flex-row items-stretch overflow-hidden relative border-b border-gray-100 package-two">
          <div className="w-full sm:w-6/12 p-6 sm:p-8 pb-[220px] sm:pb-8 h-auto sm:h-[360px]">  
            <div className="mb-4 sm:mb-6">
              <p className="text-sm sm:text-md text-bluetext mb-1">
                MD08-A1 - Automotive MDVR with local storage
              </p>
              <h3 className="font-semibold text-sm md:text-lg lg:text-xl text-blueone leading-5 md:leading-6 lg:leading-6 mr-0 sm:mr-16">
                <span className="text-primary">Mobile Digital Video Record (MDVR)</span> with Live Streaming, fast SSD storage and optional Cloud storage
              </h3>
            </div>

            <div className="text-blueone text-sm">
              <p className="mb-4 mt-0 sm:mt-[60px]">
                <strong>Robust, upgradable MDVR with 4-Channels & SSD Storage</strong> that is future-proofed with ability to upgrade when necessary.
              </p>
              <p>
                It supports up to 4TB of industrial-grade Solid State storage and ability to display video on an external monitor in the vehicle.
              </p>
              <a href="#camera-hardware-options" className="text-primary">View upgrade list ></a>
            </div>
          </div>

          <div className="w-full sm:w-6/12 pt-0 md:pt-4 px-4 md:pr-4 flex items-stretch justify-center relative grow">
            <p className="text-[10px] text-blueone absolute bottom-[12px] sm:bottom-[3%] right-auto sm:right-[5%]">Note: Device physical appearance may change over time.</p>
          </div>
        </div>

        {/* SECTION */}
        <div className="grid grid-cols-1 gap-0 md:grid-cols-3 md:gap-0 lg:gap-0 w-full z-10">
          
          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/chat-mark.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Automatic video recording</strong> when 
                  vehicle is switched on. Includes detection
                  of shocks which triggers mandatory 
                  recording so that important videos are
                  not lost.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex flex-col justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/hard-drive.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Large internal storage</strong> of up to 4TB on
                  ruggedized Solid-State Drive (SSD) & 
                  SD card ensures long video recording 
                  and longevity in operational environment.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/lock-open.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Secure physical file storage</strong> with 
                  key locks to prevent unauthorised 
                  access and tempering of video evidence.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/upload.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>H.264 video compression</strong> reduces
                  video file size while maintaining a 
                  sharp and crisp image.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/tv.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Audio/Video VGA output</strong> provides ability
                  to display live videos and playback of 
                  past recorded videos on an external
                  monitor in the vehicle.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r-0">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/chip.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>G-force sensor</strong> potential collisions
                  and risky incidents which triggers
                  mandatory video recording that is
                  safely stored.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/boxes.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Upgrade option: Live GPS tracking </strong>  
                  provides real-time location update 
                  every 5-20 seconds with 12 months 
                  location history.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex justify-self-auto border-b border-r">  
            <div className="p-6 sm:p-8 text-blueone">
              <div className="overflow-hidden">
                <StaticImage
                  alt="Wifi"
                  src="../../../../static/images/mdvr/icons/blue/truck.png"
                  className="w-[24px] h-[24px] sm:w-[24px] sm:h-[24px]"
                />
              </div>

              <div className="pt-1 pb-2">
                <p className="text-sm">
                  <strong>Upgrade option: Fleet Management System </strong>  
                  with advanced monitoring 
                  capabilities & extensive operational 
                  reports for day-to-day fleet operations.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden flex border-b border-r-0">  
            <div className="w-full p-6 sm:p-8 text-blueone flex flex-col items-center justify-center">
              <a
                href="https://apps.katsana.com/get-quotation"
                className="inline-block px-5 py-2 font-semibold text-white rounded-full md:self-center px-6 pt-2 pb-3 md:text-base leading-0 bg-primary"
              >
                Need help? Contact us
              </a>
              <a
                href="mailto:sales@katsana.com"
                className="mt-3 sm:mt-3 text-sm text-primary md:flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-bold">or email sales@katsana.com</span>
              </a>
            </div>
          </div>

        </div>

        {/* SECTION */}
        
      </div>

    </div>
    
  );
};

export default PackageTwo;
